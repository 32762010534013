/* Override default border from modal content */
.loader-modal div.modal-content {border:0px; background-color: transparent !important;}

.modal-backdrop {
    background-color: #e9e9e9 !important;
}

.modal-backdrop.show {
    opacity: .5 !important;
}

